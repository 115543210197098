export default {
  init () {
    // JavaScript to be fired on all pages

    /**
     * 
     * Add Toggle Icon
     * 
     * @kgislason
     */

    const addToggleIcon = function() {
      $('.menu-item-has-children').each(function () {
        $(this).find('> a').addClass('d-flex')
        $(this)
          .find('> a')
          .append(
            '<button class="menu-toggle px-2 py-0 ml-1 btn btn-sm ml-auto"><i class="fas fa-chevron-down"></i></button>'
          )
      })
    }

    addToggleIcon()

    /**
     * Reset submenu
     * 
     * @kgislason
     */

    const resetSubmenu = function() {
      $('.sub-menu').each(function () {
        $(this).hide()
      })
      $('.menu-item-has-children').each(function() {
        const icon = $(this)
          .find('i');
        
          if (icon.hasClass('fa-chevron-up')) {
            icon
              .removeClass('fa-chevron-up')
              .addClass('fa-chevron-down')  
          }
      })
    }

    resetSubmenu();

    /**
     * Detect Window Width
     * Use to manage different behaviour for mobile and desktop
     *
     * @kgislason
     */

    const detectWindowWidth = function () {
      let windowWidth = $('body').width()
      resetSubmenu()

      if (windowWidth > 991) {
        $('body').addClass('is-desktop')
      } else {
        $('body').addClass('is-mobile')
      }

      $(window).on('resize', function () {
        windowWidth = $('body').width();
        resetSubmenu();

        // Modify classes
        if (windowWidth > 991) {
          $('body').addClass('is-desktop')
          $('body').removeClass('is-mobile')
        } else {
          $('body').removeClass('is-desktop')
          $('body').addClass('is-mobile')
        }
      })
    }

    detectWindowWidth()

    /**
     * Primary Nav Hover
     *
     * Manage submenu behaviour for touch screens
     *
     * @kgislason
     */

    const primaryNavHover = function () {
      $('.menu-item-has-children').on('mouseenter', function () {
        if ($('body').hasClass('is-desktop')) {
          if ($(this).find('.sub-menu').is(':visible')) {
            setTimeout(function () {
              $(this).find('.sub-menu').hide()
              $(this)
                .find('i')
                .removeClass('fa-chevron-up')
                .addClass('fa-chevron-down')
            }, 500)
          } else {
            $('.sub-menu').each(function () {
              $(this).hide()
            })
            $(this).find('.sub-menu').show()
            $(this)
              .find('i')
              .removeClass('fa-chevron-down')
              .addClass('fa-chevron-up')
          }
        }
      })

      $('.menu-item-has-children').on('mouseleave', function () {
        if ($('body').hasClass('is-desktop')) {
          resetSubmenu();
        }
      })
    }

    primaryNavHover()

    /**
     * Primary Nav Hover
     *
     * Manage behaviour for desktops and devices with hover state
     *
     * @kgislason
     */

    function primaryNavTouch () {
      // Open the Sun Menu on click
      $('.menu-toggle').on('click', function (e) {
        e.preventDefault()
        if (
          $(this)
            .closest('.menu-item-has-children')
            .find('.sub-menu')
            .is(':visible')
        ) {
          $(this).closest('.menu-item-has-children').find('.sub-menu').hide()
          $(this)
            .find('i')
            .removeClass('fa-chevron-up')
            .addClass('fa-chevron-down')
        } else {
          resetSubmenu();
          $(this).closest('.menu-item-has-children').find('.sub-menu').show()
          $(this)
            .find('i')
            .removeClass('fa-chevron-down')
            .addClass('fa-chevron-up')
        }
      })
    }

    primaryNavTouch()
  },
  finalize () {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
}
